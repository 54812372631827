import { mapGetters, mapState } from 'vuex'
import IconChevronDownMini from '~/assets/icons/ChevronDownMini.svg'

export default {
  name: 'Breadcrumbs',
  components: { IconChevronDownMini },
  props: {
    breadcrumbs: {
      type: Array,
      required: true
    },
    customPage: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      transparentNavbarScrollActive: true
    }
  },
  computed: {
    ...mapGetters({
      headerHeight: 'header/headerHeight'
    }),
    ...mapState({
      transparentNavbar: state => state.header.transparentNavbar,
    })
  },
  mounted () {
    this.$store.dispatch('header/setHeaderHeight')
    window.addEventListener('scroll', this.updateTransparentNavbarScrollActive)
  },
  destroyed () {
    window.removeEventListener('scroll', this.updateTransparentNavbarScrollActive)
  },
  methods: {
    updateTransparentNavbarScrollActive (event) {
      if (!this.transparentNavbarScrollActive && window.scrollY < document.getElementById('header')?.clientHeight * 2) {
        this.transparentNavbarScrollActive = true
      } else if (this.transparentNavbarScrollActive && window.scrollY > document.getElementById('header')?.clientHeight * 2) {
        setTimeout(() => { this.transparentNavbarScrollActive = window.scrollY < document.getElementById('header').clientHeight * 2 }, 500)
      }
    },
    contentClick (contentType, subContentType, itemID, options) {
      this.$analytics.selectContent(contentType, subContentType, itemID, options)
    }
  }
}
